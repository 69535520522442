import './css/index.css'
import { MobileHeader, Header, Footer, About, Experience } from './components'
import { useEffect } from 'react'


function App() {

  let handleScroll = () => {
    let position = window.scrollY
    let header = document.getElementsByClassName('header')[0]
    if (position > 100) {
      header.classList.add('light')
    } else {
      header.classList.remove('light')
    }
  }

  useEffect(() => {
    document.removeEventListener('scroll', handleScroll)
    document.addEventListener('scroll', handleScroll)
  });

  return (
    <>
      <MobileHeader/>
      <Header />
      <div className="main">
        <div className="description">
          <h1>Hi! I'm Lambert Chan.</h1>
          <p>Software Developer</p>
          <button><a href="#about">Get to know me →</a></button>
        </div>
      </div>
      <About />
      <Experience />
      <Footer />
    </>
  )
}

export default App;
