function toggleMenu() {
    let mobileMenu = document.getElementById('mobile-links');
    mobileMenu.style.display = mobileMenu.style.display === "none" ? "flex" : "none" 

}

export default function MobileHeader() {
    return (
        <>
            <div class="mobile-header">
                <a href="#home" className="logo">lambertchan</a>
                <button onClick={toggleMenu}><i class="fa fa-bars"></i></button>
            </div>
            <div id="mobile-links">
                <a href="#about">about</a>
                <a href="#experience">experience</a>
            </div>
        </>
    )
}