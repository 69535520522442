import LambertHeadshot from '../img/Lambert_Head_Shot.jpg'

const skills = ["React", 'GraphQL', "Next.js", "HTML5/CSS3", "Netlify", "Java", "Python", "Git", "Jest", "Jira", "Flutter"]

export default function About() {
    return (
        <div id="about" className="section">
            <h1>about</h1>
            <div className="col-2">
                <div>
                    <div className="profile-social">
                        <img src={LambertHeadshot} alt="Lambert headshot" className="headshot" width="250px"></img>
                        <div className="social">
                            <span><a href="mailto:lambert.lh.chan@gmail.com" class="fa fa-envelope"> </a></span>
                            <span><a href="https://www.facebook.com/lambochan1994/" class="fa fa-facebook" target="_blank" rel="noreferrer" > </a></span>
                            <span><a href="https://www.linkedin.com/in/lambert-chan/" class="fa fa-linkedin" target="_blank" rel="noreferrer" > </a></span>
                            <span><a href="https://github.com/lambert-chan" class="fa fa-github" target="_blank" rel="noreferrer" > </a></span>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        As a graduate of the Computer Systems Technology program at BCIT,
                        I enjoy creating front-end experiences that enable users and help people to reach their potentials.
                    </p>
                    <p>
                        Currently at Ensemble Systems, I have worked with a variety of clients developing websites and applications to suit their needs.
                        Alongside that, I have developing user interfaces for chiropractic clinics using a React and Java stack at MRX Solutions as well as redesigned the BarTalk microsite for the Canadian Bar Association - BC branch.
                    </p>
                </div>
            </div>
            <h1>skills</h1>
            <div className="wrap">
                {skills.map(skill => <span className="tag">{skill}</span>)}
            </div>
        </div>
    )
}